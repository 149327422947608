/* ---- Imports ---- */

import { createRouter, createWebHistory } from 'vue-router'

/* ---- Routes ---- */

import autenticacao from './autenticacao'
import app from './app'
import areaDeMembros from './areaDeMembro'

/* ---- System ---- */

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...autenticacao,
    ...app,
    ...areaDeMembros
  ]
})

router.afterEach((to) => { document.title = to.meta.title })

export default router
