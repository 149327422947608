export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: {
      logged: false,
      title: 'Essa página não existe... | LevelMember.com',
      layout: 'default-layout'
    }
  },
  {
    path: '/areas',
    name: 'areas',
    component: () => import('../components/app/areas/Areas.vue'),
    meta: {
      logged: true,
      title: 'Suas áreas de membros | LevelMember.com',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/planos',
    name: 'planos',
    component: () => import('../components/app/planos/Planos.vue'),
    meta: {
      logged: true,
      title: 'Planos | LevelMember.com',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/afiliados',
    name: 'afiliados',
    component: () => import('../components/app/afiliados/Afiliados.vue'),
    meta: {
      logged: true,
      title: 'Afiliados | LevelMember.com',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/notificacoes',
    name: 'notificacoes',
    component: () => import('../components/app/notificacoes/Notificacoes.vue'),
    meta: {
      logged: true,
      title: 'Suas notificações | LevelMember.com',
      layout: 'navbar-layout'
    }
  }
]
